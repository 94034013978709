// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link } from 'gatsby-theme-material-ui';
import {
  Typography,
  Grid,
  CardActionArea,
  Card,
  CardContent,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

import dayjs from '../utils/dayjs';
import MainLayout from '../components/MainLayout';
import SmallHero from '../components/SmallHero';
import Block from '../components/Block';
import ArticleTag from '../components/ArticleTag';
import backgroundImage from '../images/art-lasovsky-8XddFc6NkBY-unsplash.jpg';

export const pageQuery = graphql`
query {
  allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
    nodes {
      id
      excerpt(pruneLength: 250)
      frontmatter {
        title
        date
        slug
        tags
        excerpt
      }
    }
  }
}
`;

function ArticleCard({ post }) {
  const date = dayjs(post.frontmatter.date).format('YYYY / MMMM');
  const Tags = post.frontmatter.tags?.map((tag) => <ArticleTag key={tag} label={tag} variant="outlined" />);
  const excerpt = post.frontmatter.excerpt || `${post.excerpt}...`;

  const cardStyle = {
    display: 'block',
    height: '230px',
    overflow: 'hidden',
    marginBottom: '15px',
  };

  return (
    <Grid item xs={12} sm={6}>
      <Card raised>
        <CardActionArea component={Link} to={post.frontmatter.slug}>
          <CardContent>
            <div style={cardStyle}>
              <Typography variant="subtitle1" gutterBottom>
                {post.frontmatter.title}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {date}
              </Typography>
              <Typography gutterBottom>
                {excerpt}
              </Typography>
            </div>
            {Tags}
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

ArticleCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  post: PropTypes.object.isRequired,
};

export function Head() {
  return <title>Ideat - Veikko Mäkinen</title>;
}

const Info = styled(Typography)(() => ({
  fontSize: 16,
  // color: '#fff',
  marginBottom: 14,
  marginLeft: 2,
}));

function ArticlesIndex({ data }) {
  const Posts = data?.allMdx.nodes.map((node) => <ArticleCard key={node.id} post={node} />);
  const sxInfoCard = { backgroundColor: 'extras.blue.light', minHeight: { sm: '220px' } };
  return (
    <MainLayout>
      <SmallHero title="Ideat" backgroundImage={backgroundImage} overlayColor="#002" overlayOpacity={0.4} />
      <Block sx={{ backgroundColor: 'extras.blue.main', color: 'white' }}>
        <Typography variant="h6" color="white" sx={{ marginBottom: 2 }}>
          Ajatuksia yrittämisestä, johtamisesta, tekniikasta
          ja joskus ihan vain elämästä yleensä.
        </Typography>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={4}>
            <Card sx={sxInfoCard}>
              <CardContent>
                <ArticleTag label="idea" css={css` marginBottom: 15px`} />
                <Info>
                  Ajatuksia, oivalluksia ja vinkkejä.
                  Niitä on tarttunut mukaan
                  matkan varrelta, löytynyt kirjoista tai ne on opittu viisamilta.
                </Info>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={sxInfoCard}>
              <CardContent>
                <ArticleTag label="tech" />
                <Info>
                  Tekniikkaa, tietojärjestelmiä ja ohjelmistokehitystä.
                  Joskus syvästä päädystä, joskus laajemmalle yleisölle.
                </Info>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={sxInfoCard}>
              <CardContent>
                <ArticleTag label="caution" />
                <Info>
                  Bisnesneuvot ovat pullollaan valmiita vastauksia,
                  ja yksinkertaistuksia.
                  Niille on paikkansa, mutta ota ne ripauksella suolaa.
                </Info>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Block>
      <Block>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {Posts}
        </Grid>
      </Block>
    </MainLayout>
  );
}

ArticlesIndex.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default ArticlesIndex;
